import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const ThumbDefault = () => {
  return (
    <StaticImage
      src="../../images/thumb_default.jpg"
      placeholder="blurred"
      width={370}
      height={250}
      alt="default"
      quality="100"
    />
  )
}

export default ThumbDefault
