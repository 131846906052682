import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const Nina = () => {
  return (
    <StaticImage
      src="../../images/nina.png"
      width={600}
      placeholder="blurred"
      alt="nina"
      quality="100"
    />
  )
}

export default Nina
