import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const Slogan = () => {
  return (
    <StaticImage
      src="../../images/slogan.png"
      width={228}
      height={105}
      layout="fixed"
      placeholder="blurred"
      alt="slogan"
      quality="100"
    />
  )
}

export default Slogan
