import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { string } from 'prop-types';

const AsideActu = ({ id }) => {
  const data = useStaticQuery(graphql`{
  allMarkdownRemark(
    limit: 3
    sort: {frontmatter: {date: DESC}}
    filter: {frontmatter: {type: {in: ["actu"]}}}
  ) {
    edges {
      node {
        frontmatter {
          slug
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 490
                height: 250
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        id
      }
    }
  }
}`);
  const { edges } = data.allMarkdownRemark;
  return (
    <div className="sidebar-area">
      <div className="widget-wrap recent-post-widget mb-30">
        <h3 className="widget-title">Actualités récentes</h3>
        {edges
          .filter((actu) => actu.node.id !== id)
          .map((actu) => {
            const image = getImage(actu.node.frontmatter.featuredImage);
            return (
              <div className="recent-post-wrap" key={actu.node.id}>
                <GatsbyImage image={image} alt={actu.node.frontmatter.title} />
                <h4>
                  <Link to={actu.node.frontmatter.slug}>
                    {actu.node.frontmatter.title}
                  </Link>
                </h4>
              </div>
            );
          })}
      </div>
    </div>
  );
};

AsideActu.propTypes = {
  id: string,
};

export default AsideActu;
