import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const TrombiDjalil = () => {
  return (
    <StaticImage
      src="../../images/trombi_djalil.jpg"
      placeholder="blurred"
      width={370}
      height={443}
      alt="Djalil PENAUD"
      quality="100"
    />
  );
};

export default TrombiDjalil;
