import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Cm17 from './images/cm17';
import Rena from './images/rena';
import Amv from './images/amv';
import Cdk17 from './images/cdk17';

const HomeBrand = () => (
  <div className="brand-area pt-30 pb-30">
    <Container>
      <Row>
        <Col xl={12} align={'center'}>
          <div className="brand-wrap">
            <a
              href="https://www.ffkarate.fr/arts-martiaux-vietnamiens/"
              target="_blank"
              rel="noreferrer"
            >
              <Amv />
            </a>
          </div>
          <div className="brand-wrap">
            <a
              href="https://sites.ffkarate.fr/charentemaritime/"
              target="_blank"
              rel="noreferrer"
            >
              <Cdk17 />
            </a>
          </div>
          <div className="brand-wrap">
            <a
              href="https://www.nouvelle-aquitaine.fr/"
              target="_blank"
              rel="noreferrer"
            >
              <Rena />
            </a>
          </div>
          <div className="brand-wrap">
            <a
              href="https://la.charente-maritime.fr/"
              target="_blank"
              rel="noreferrer"
            >
              <Cm17 />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);
export default HomeBrand;
