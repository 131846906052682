import React from 'react';
import { Link } from 'gatsby';
import Ffkda from './images/ffkda';
import FooterAgenda from './footer_agenda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
const Footer = () => (
  <footer className="footer-area">
    <div className="footer-top bg-black">
      <div className="container">
        <Row className="pt-40 pb-40">
          <Col xl={4} md={6}>
            <FooterAgenda />
          </Col>
          <Col xl={4} md={12}>
            <div className="footer-widgets contact-widget">
              <h3 className="widget-title-2">Nous contacter</h3>
              <span>Nina DELAGRANGE</span>
              <ul>
                <li>
                  <a href="#">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                    <span>06 48 21 86 47</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/Viet-Vo-Dao-Ile-De-R%C3%A9-103181524847131"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                    <span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.vietvodaoiledere.com/">
                    <FontAwesomeIcon icon={faGlobe} />
                    <span>www.vietvodaoiledere.com</span>
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={4} md={6}>
            <div className="footer-widgets footer-about-widget">
              <div className="footer-logo">
                <a
                  href="https://www.ffkarate.fr/arts-martiaux-vietnamiens/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Ffkda />
                </a>
              </div>
              <p>
                La délégation ministérielle pour l’organisation et le
                développement des Arts Martiaux Vietnamiens en France est placée
                sous la responsabilité de la Fédération Francaise de Karaté.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div className="footer-bottom">
      <Container>
        <Row className="align-items-center">
          <Col xl={6} lg={6}>
            <div className="copyright text-xl-left text-lg-left text-center mb-md-10 mb-xs-10">
              <p>
                © {new Date().getFullYear()}, Viet Vo Dao île de Ré - École
                Thanh Long Truong Son Phaï
              </p>
            </div>
          </Col>
          <Col xl={6} lg={6}>
            <div className="footer-menu">
              <ul>
                <li>
                  <Link to="/politique-de-confidentialite/">
                    Politique de Confidentialité
                  </Link>
                </li>
                <li> | </li>
                <li>
                  <Link to="/mentions-legales/">Mentions Légales</Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </footer>
);

export default Footer;
