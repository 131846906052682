import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const TrombiTom = () => {
  return (
    <StaticImage
      src="../../images/trombi_tom.jpg"
      placeholder="blurred"
      width={370}
      height={443}
      alt="Tom TURBE"
      quality="100"
    />
  );
};

export default TrombiTom;
