import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const MaitreNGuyen = () => {
  return (
    <StaticImage
      src="../../images/gerardnguyen.jpg"
      width={410}
      placeholder="blurred"
      alt="gerardnguyen"
      quality="100"
    />
  );
};

export default MaitreNGuyen;
