import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleRight,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';

const FooterAgenda = () => {
  const data = useStaticQuery(graphql`{
  allMarkdownRemark(
    sort: {frontmatter: {date: ASC}}
    filter: {frontmatter: {type: {in: ["agenda"]}}}
  ) {
    edges {
      node {
        frontmatter {
          date(formatString: "DD MMM yyyy")
          slug
          title
        }
        id
      }
    }
  }
}`);

  const now = Date.now();
  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  };
  const futurDates = data.allMarkdownRemark.edges.filter((agenda) => {
    return agenda && new Date(agenda.node.frontmatter.date).getTime() > now;
  });

  return (
    <div className="footer-widgets latest-news">
      <h3 className="widget-title-2">Agenda</h3>
      {futurDates.slice(0, 2).map((agenda) => (
        <div key={agenda.node.id} className="news-wrap">
          <div className="news-content">
            <h4>
              <Link to={agenda.node.frontmatter.slug}>
                {agenda.node.frontmatter.title}
              </Link>
            </h4>
            <span>
              <FontAwesomeIcon icon={faCalendarAlt} />
              {new Date(agenda.node.frontmatter.date).toLocaleDateString(
                'fr-FR',
                options
              )}
            </span>
          </div>
        </div>
      ))}
      <div className="read-more">
        <Link to="/agenda/">
          tout voir <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Link>
      </div>
    </div>
  );
};

export default FooterAgenda;
