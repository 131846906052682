import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const Ffkda = () => {
  return (
    <StaticImage
      src="../../images/ffkda.png"
      placeholder="blurred"
      width={125}
      alt="amv"
      quality="100"
    />
  )
}

export default Ffkda
