import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { groupBySaison } from '../utils/saisonUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const AsideAgendaArchive = () => {
  const data = useStaticQuery(graphql`{
  allMarkdownRemark(
    sort: {frontmatter: {date: DESC}}
    filter: {frontmatter: {type: {in: ["agenda"]}}}
  ) {
    edges {
      node {
        id
        frontmatter {
          date
          slug
          title
        }
      }
    }
  }
}`);
  const { edges } = data.allMarkdownRemark;
  const groupArrays = groupBySaison(edges);
  return (
    <div className="sidebar-area">
      <div className="widget-wrap category-widget mb-30">
        {groupArrays.map((agenda) => {
          return (
            <div key={agenda.saison}>
              <h3 className="widget-title">{agenda.saison}</h3>
              <ul>
                {agenda.elements.map((element) => {
                  return (
                    <li key={element.node.id}>
                      <Link to={element.node.frontmatter.slug}>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                        &nbsp;
                        {new Date(
                          element.node.frontmatter.date
                        ).toLocaleDateString('fr-FR')}
                        <br /> {element.node.frontmatter.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AsideAgendaArchive;
