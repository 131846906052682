import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const TrombiNina = () => {
  return (
    <StaticImage
      src="../../images/trombi_nina.jpg"
      placeholder="blurred"
      width={370}
      height={443}
      alt="Nina DELAGRANGE"
      quality="100"
    />
  );
};

export default TrombiNina;
