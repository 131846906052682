import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import {
  TrombiNina,
  TrombiMika,
  TrombiThierry,
  TrombiJf,
  TrombiTom,
  TrombiAlain,
  TrombiVirgil,
  TrombiDjalil,
} from './images';

const HomeTeam = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "team-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
    }
  `);
  const pluginImage = getImage(image);
  return (
    <BgImage
      className="team-area-3 bg-fix pt-30 pb-30"
      image={pluginImage}
      style={{ minHeight: 200 }}
    >
      <Container>
        <Row>
          <Col xl={12}>
            <div className="section-title-2 text-white bar-theme-color text-center mb-355">
              <h3>L&apos;équipe pédagogique</h3>
              <span>Enseignants</span>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={6} md={6}>
            <div className="team-wrap-3 mb-30">
              <div className="team-img">
                <TrombiJf />
              </div>
              <div className="team-content">
                <h3>Jean-François DOUAY</h3>
                <span>Responsable Technique</span>
                <br />
                <span>6e DANG</span>
                <br />
                <span>Diplôme d’Instructeur Fédéral</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={6}>
            <div className="team-wrap-3 mb-30">
              <div className="team-img">
                <TrombiTom />
              </div>
              <div className="team-content">
                <h3>Tom TURBÉ</h3>
                <span>4e DANG</span>
                <br />
                <span>Diplôme d’Instructeur Fédéral</span>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="team-wrap-3 mb-30">
              <div className="team-img">
                <TrombiMika />
              </div>
              <div className="team-content">
                <h3>Mickael SAUNOT</h3>
                <span>4e DANG</span>
                <br />
                <span>Diplôme d’Instructeur Fédéral</span>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="team-wrap-3 mb-30">
              <div className="team-img">
                <TrombiNina />
              </div>
              <div className="team-content">
                <h3>Nina DELAGRANGE</h3>
                <span>3e DANG</span>
                <br />
                <span>Diplôme d’Instructeur Fédéral</span>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="team-wrap-3 mb-30">
              <div className="team-img">
                <TrombiThierry />
              </div>
              <div className="team-content">
                <h3>Thierry TURBÉ</h3>
                <span>1er DANG</span>
                <br />
                <span>Diplôme d’Instructeur Fédéral</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6}>
            <div className="team-wrap-3 mb-30">
              <div className="team-img">
                <TrombiAlain />
              </div>
              <div className="team-content">
                <h3>Alain SAUNOT</h3>
                <span>1er DANG</span>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="team-wrap-3 mb-30">
              <div className="team-img">
                <TrombiVirgil />
              </div>
              <div className="team-content">
                <h3>Virgil LAMARCHE</h3>
                <span>1er DANG</span>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="team-wrap-3 mb-30">
              <div className="team-img">
                <TrombiDjalil />
              </div>
              <div className="team-content">
                <h3>Djalil PENAUD</h3>
                <span>1er DANG</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </BgImage>
  );
};

export default HomeTeam;
