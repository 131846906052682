import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';

const HomeCours = () => (
  <div className="schedule-area bg-black pt-30 pb-30 d-none d-lg-block">
    <Container>
      <Row>
        <Col xl={12}>
          <div className="section-title-2 bar-theme-color text-white text-center mb-35">
            <h3>Lieux et horaires</h3>
            <span>Les Cours</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <div className="schedule-table">
            <Table className="table table-bordered table-dark">
              <thead className="bg-gra">
                <tr>
                  <th></th>
                  <th>Mardi</th>
                  <th>Mercredi</th>
                  <th>Jeudi</th>
                  <th>Samedi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="place">La Flotte</td>
                  <td></td>
                  <td>
                    <h4>Cours Adultes et Enfants + 13ans</h4>
                    <p>19h00 - 20h30</p>
                  </td>
                  <td></td>
                  <td>
                    <h4>Cours Enfants</h4>
                    <p>14h15 - 15h30</p>
                  </td>
                </tr>
                <tr>
                  <td className="place">Sainte-Marie</td>
                  <td>
                    <h4>Cours Enfants</h4>
                    <p>18h30 - 19h45</p>
                    <br />
                    <h4>Cours Adultes</h4>
                    <p>19h00 - 21h00</p>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="place">Saint-Martin</td>
                  <td></td>
                  <td></td>
                  <td>
                    <h4>Cours Adultes et Enfants + 13ans</h4>
                    <p>19h30 - 21h00</p>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="place">Les Portes</td>
                  <td></td>
                  <td>
                    <h4>Cours Enfants </h4>
                    <p>17h15 - 18h00</p>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);
export default HomeCours;
