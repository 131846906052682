import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { node } from 'prop-types';

const HomeSlider = ({ children }) => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "shape-25.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              quality: 100
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(image);

  return (
    <div className="slider-area">
      <BgImage
        className="home-slider-5 home-slider-shape"
        image={pluginImage}
        style={{ minHeight: 200 }}
      >
        {children}
      </BgImage>
    </div>
  );
};

HomeSlider.propTypes = {
  children: node,
};

export default HomeSlider;
