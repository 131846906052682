import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const Rosace = () => {
  return (
    <StaticImage
      src="../../images/rosace.png"
      width={95}
      height={95}
      layout="fixed"
      placeholder="blurred"
      alt="rosace"
      quality="100"
    />
  )
}

export default Rosace
