import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const Ecole = () => {
  return (
    <StaticImage
      src="../../images/ecole.png"
      width={220}
      layout="fixed"
      placeholder="blurred"
      alt="ecole"
      quality="100"
    />
  );
};

export default Ecole;
