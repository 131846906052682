import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const Cdk17 = () => {
  return (
    <StaticImage
      src="../../images/cdk17.png"
      placeholder="blurred"
      width={80}
      height={80}
      alt="CDK17"
      quality="100"
    />
  );
};

export default Cdk17;
