import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ThumbDefault from './images/thumb_default';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleRight,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
const HomeActu = () => {
  const data = useStaticQuery(graphql`{
  allMarkdownRemark(
    limit: 3
    sort: {frontmatter: {date: DESC}}
    filter: {frontmatter: {type: {in: ["actu"]}}}
  ) {
    edges {
      node {
        frontmatter {
          date(formatString: "DD MMM yyyy", locale: "fr-FR")
          slug
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 416
                height: 250
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        id
      }
    }
  }
}`);
  const { edges } = data.allMarkdownRemark;
  return (
    <div className="blog-area-2 bg-black pt-30 pb-30">
      <Container>
        <Row>
          <Col xl={12}>
            <div className="section-title-2 text-white bar-theme-color text-center mb-35">
              <h3>La vie du club</h3>
              <span>Actualités</span>
            </div>
          </Col>
        </Row>
        <Row>
          {edges.map((actu) => {
            const image = getImage(actu.node.frontmatter.featuredImage);
            return (
              <Col xl={4} md={6} key={actu.node.id}>
                <div className="blog-wrap-2 mb-30">
                  <div className="blog-thumb mb-35">
                    <Link to={actu.node.frontmatter.slug}>
                      {image ? (
                        <GatsbyImage
                          image={image}
                          alt={actu.node.frontmatter.title}
                        />
                      ) : (
                        <ThumbDefault />
                      )}
                    </Link>
                  </div>
                  <div className="blog-meta">
                    <span>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                      {actu.node.frontmatter.date}
                    </span>
                  </div>
                  <div className="blog-content text-white">
                    <h3>
                      <Link to={actu.node.frontmatter.slug}>
                        {actu.node.frontmatter.title}
                      </Link>
                    </h3>
                    <Link to={actu.node.frontmatter.slug} className="read-more">
                      En savoir plus
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </Link>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default HomeActu;
