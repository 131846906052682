import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const Cm17 = () => {
  return (
    <StaticImage
      src="../../images/cm_17.png"
      placeholder="blurred"
      width={80}
      height={80}
      alt="Charente Maritime"
      quality="100"
    />
  );
};

export default Cm17;
