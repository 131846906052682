import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const Rena = () => {
  return (
    <StaticImage
      src="../../images/re_na.png"
      placeholder="blurred"
      width={200}
      alt="Région Nouvelle Aquitaine"
      quality="100"
    />
  );
};

export default Rena;
