import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const Amv = () => {
  return (
    <StaticImage
      src="../../images/amv_ffk.png"
      placeholder="blurred"
      width={238}
      height={76}
      alt="amv"
      quality="100"
    />
  )
}

export default Amv
