import React from 'react';
import { Link } from 'gatsby';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Rosace, Slogan, Ecole } from './images';

const Header = () => (
  <header className="header-area header-style-5">
    <Container className="align-items-center justify-content-xl-center">
      <Navbar expand="lg" bg="dark" variant="dark">
        <Navbar.Brand>
          <Link to="/">
            <Rosace />
          </Link>
        </Navbar.Brand>
        <div className="d-none d-lg-none d-sm-block d-md-block d-xxl-block">
          <Slogan />
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto float-right text-right pr-3">
            <Link
              data-rb-event-key="#link"
              className="nav-link"
              to="/presentation/"
            >
              Présentation
            </Link>
            <NavDropdown title="Ou s'entrainer" id="basic-nav-dropdown">
              <Link
                data-rb-event-key="#link"
                className="dropdown-item"
                to="/cours/"
              >
                Les Cours
              </Link>
              <Link
                data-rb-event-key="#link"
                className="dropdown-item"
                to="/infos-pratiques/"
              >
                Infos Pratiques
              </Link>
            </NavDropdown>
            <Link
              data-rb-event-key="#link"
              className="nav-link"
              to="/actualites"
            >
              Actualités
            </Link>
            <Link data-rb-event-key="#link" className="nav-link" to="/agenda">
              Agenda
            </Link>
            <Link data-rb-event-key="#link" className="nav-link" to="/liens/">
              Liens
            </Link>
          </Nav>
          <div className="d-none d-lg-none d-xl-block">
            <Ecole />
          </div>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </header>
);

export default Header;
