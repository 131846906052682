import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const TrombiMika = () => {
  return (
    <StaticImage
      src="../../images/trombi_mika.jpg"
      placeholder="blurred"
      width={370}
      height={443}
      alt="Mickael SAUNOT"
      quality="100"
    />
  );
};

export default TrombiMika;
